import React from 'react'

export default class ScrollTop extends React.Component {
    constructor(props) {
        super(props)
        this.onScrollBound = this.onScroll.bind(this)
        this.state = {
            scrollTopVisible: false,
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.onScrollBound)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollBound)
    }
    onScroll(event) {
        let threshold = window.innerHeight * 2
        let scrollTopVisible = false
        if (window.scrollY > threshold) {
            scrollTopVisible = true
        }
        if (scrollTopVisible !== this.state.scrollTopVisible) {
            this.setState({
                scrollTopVisible,
            })
        }
    }
    scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'auto' })
    }
    render() {
        const { scrollTopVisible } = this.state
        return scrollTopVisible ? (
            <div className="container-fluid px-yd">
                <div className="row">
                    <div className="col-12 justify-content-end d-flex">
                        <div
                            className="ScrollTop"
                            style={{
                                position: 'fixed',
                                bottom: 0,
                                zIndex: 999,
                            }}
                        >
                            <button
                                className="d-flex align-items-center justify-content-center text-white border-0 p-0"
                                onClick={this.scrollTop}
                                style={{
                                    fontSize: `2rem`,
                                    height: 46,
                                    width: 46,
                                    marginRight: 2,
                                    marginBottom: 20,
                                    borderRadius: '50%',
                                    background: `rgba(0,0,0,0.33)`,
                                    cursor: 'pointer',
                                }}
                            >
                                <ArrowComponent style={{ display: 'block' }} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    }
}

const ArrowComponent = props => (
    <svg width={40} height={40} {...props}>
        <g fill="none" fillRule="evenodd">
            <g stroke="#FFF" strokeWidth={2}>
                <path d="M13.036 17.5l7.464-7.464h0l7.464 7.464" />
                <path d="M20.5 11.806v18" strokeLinecap="square" />
            </g>
        </g>
    </svg>
)
