import React, { Component } from 'react'
import IdeasListItem from '../../components/ideas-list-item/IdeasListItem'
import { AutoSizer, ColumnSizer, Grid, WindowScroller } from 'react-virtualized'
import 'react-virtualized/styles.css'
import './virtualized-list.scss'

export default class VirtualizedPostsList extends Component {
    render() {
        const posts = this.props.posts
        
        return (
            <WindowScroller>
                {({
                    height,
                    isScrolling,
                    registerChild,
                    scrollTop,
                    onChildScroll,
                }) => (
                    <AutoSizer disableHeight>
                        {({ width }) => {
                            this.columnCount = 3
                            let rowCount = Math.ceil(posts.length / 3)
                            // Tablet
                            if (
                                typeof window !== 'undefined' &&
                                window.innerWidth < 992
                            ) {
                                this.columnCount = 2
                                rowCount = Math.ceil(posts.length / 2)
                            }
                            // Mobile
                            if (
                                typeof window !== 'undefined' &&
                                window.innerWidth < 768
                            ) {
                                this.columnCount = 1
                                rowCount = posts.length
                            }
                            return (
                                <ColumnSizer
                                    key="GridColumnSizer"
                                    width={width}
                                    columnMinWidth={0}
                                    columnCount={this.columnCount}
                                >
                                    {({ adjustedWidth, columnWidth }) => {
                                        let singleWidth =
                                            adjustedWidth / this.columnCount
                                        let newRowHeight = singleWidth * 1.125 // * 1.1446
                                        return (
                                            <div ref={registerChild}>
                                                <Grid
                                                    className="VirtualizedPostsList"
                                                    ref={ref =>
                                                        (this._grid = ref)
                                                    }
                                                    width={adjustedWidth}
                                                    height={height}
                                                    autoHeight={true}
                                                    columnCount={this.columnCount}
                                                    columnWidth={columnWidth}
                                                    rowCount={rowCount}
                                                    rowHeight={newRowHeight}
                                                    cellRenderer={
                                                        this._renderCell
                                                    }
                                                    isScrolling={isScrolling}
                                                    onScroll={onChildScroll}
                                                    scrollTop={scrollTop}
                                                    overscanRowCount={4}
                                                />
                                            </div>
                                        )
                                    }}
                                </ColumnSizer>
                            )
                        }}
                    </AutoSizer>
                )}
            </WindowScroller>
        )
    }
    _renderCell = ({ columnIndex, key, rowIndex, style }) => {
        //let test = (columnIndex) * (rowIndex) +1
        const postIndex = rowIndex * this.columnCount + columnIndex

        const post = this.props.posts[postIndex]
        //console.log(postIndex )
        return (
            <div key={key} className="Cell" style={style}>
                {post && <IdeasListItem post={post.node} />}
            </div>
        )
    }
}
