import React, { Component } from 'react'
import { graphql } from 'gatsby'
import IdeasListingLayout from '../../layouts/ideas/IdeasListingLayout'
import VirtualizedPostsList from '../../components/virtualized-posts-list/VirtualizedPostsList'
import SEO from '../../components/seo'

export default class IdeasTutorials extends Component {
    render() {
        const { page, tutorials, tutorialCategories } = this.props.data
        return (
            <IdeasListingLayout secondaryFilters={tutorialCategories.edges}>
                <SEO
                    yoast={page.yoast}
                    title={page.title}
                    description={page.content}
                    dateModified={page.modified}
                    datePublished={page.date}
                />
                <section className="IdeasTutorials container-fluid px-yd">
                    <div className="ul-ideas">
                        {tutorials && tutorials.edges && (
                            <VirtualizedPostsList posts={tutorials.edges} />
                        )}
                    </div>
                </section>
            </IdeasListingLayout>
        )
    }
}

export const pageQuery = graphql`
    query($id: String!, $categoryIds: [Int]!) {
        page: wordpressPage(id: { eq: $id }) {
            slug
            title
            content
            ...YoastPage
        }
        tutorials: allWordpressWpTutorials(
            filter: {
                tutorialtypes: {
                    elemMatch: { wordpress_id: { in: $categoryIds } }
                }
            }
        ) {
            edges {
                node {
                    ...IdeasTutorialListItemFragment
                }
            }
        }
        tutorialCategories: allWordpressWpTutorialtypes {
            edges {
                node {
                    __typename
                    name
                    slug
                }
            }
        }
    }
`
