import React from 'react'
import PropTypes from 'prop-types'
import Header from '../../components/site-header'
import Footer from '../../components/site-footer'
import LayoutBase from '../LayoutBase'
import { Link } from 'gatsby'
import classNames from 'classnames'
import './IdeasListingLayout.scss'
import ScrollTop from '../../components/scroll-top/ScrollTop'

const IdeasListingLayout = ({
    children,
    className,
    secondaryFilters,
    ...props
}) => (
    <LayoutBase
        className={classNames('IdeasListingLayout', className)}
        {...props}
    >
        <Header />
        <main>
            <nav
                role="navigation"
                className="listing-type-nav d-flex flex-column align-items-center"
            >
                <ul className="post-type-filters list-unstyled d-flex m-0">
                    <li>
                        <Link
                            className="type-link"
                            activeClassName="active"
                            to={`/ideas`}
                        >{`All Ideas`}</Link>
                    </li>
                    <li>
                        <Link
                            className="type-link"
                            activeClassName="active"
                            to={`/ideas/stories`}
                        >{`Stories`}</Link>
                    </li>
                    <li>
                        <Link
                            className="type-link"
                            activeClassName="active"
                            to={`/ideas/tutorials`}
                            getProps={({ isPartiallyCurrent }) => {
                                return isPartiallyCurrent
                                    ? { className: 'active type-link' }
                                    : null
                            }}
                        >{`Tutorials`}</Link>
                    </li>
                </ul>
                {secondaryFilters && (
                    <ul className="category-filters list-unstyled d-flex mb-0 mt-3">
                        <li>
                            <Link
                                activeClassName="active"
                                to={`/ideas/tutorials`}
                                >
                                All
                            </Link>
                        </li>
                        {secondaryFilters.map(({ node: filter }, index) => (
                            <li key={filter.slug}>
                                <Link
                                    activeClassName="active"
                                    to={`/ideas/${
                                        filter.__typename ===
                                        'wordpress__wp_tutorialtypes'
                                            ? 'tutorials'
                                            : 'stories'
                                    }/category/${filter.slug}`}
                                >
                                    {filter.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
            </nav>
            {children}
            <ScrollTop />
        </main>
        <Footer />
    </LayoutBase>
)

IdeasListingLayout.propTypes = {
    secondaryFilters: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.shape({
                __typename: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired,
            }),
        })
    ),
    children: PropTypes.node.isRequired,
}
IdeasListingLayout.defaultProps = {
    theme: 'light',
}

export default IdeasListingLayout
